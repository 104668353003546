.div-directories-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: center;
  top: 12%;
}

.div-directories {
  background-color: #aecdc4;
  border-radius: 10px;
  font-size: 1.5rem;
  max-height: 100%;
  overflow-y: auto;
  padding: 1rem;
  width: 16vw;
  max-width: 390px;
  min-width: 220px;
  /* transition: all 0.3s ease; */
}

/* @media (max-width: 1250px), (max-height: 550px) {
    .div-directories {
        display: none;
    }
} */

.p-directoryCount {
  color: #ffffff;
  font-weight: bold;
}

.div-directory-wrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
}

.span-directory-index {
  color: #ffffff;
  width: 2.5rem;
  text-align: center;
}

.span-directory-index-selected {
  color: #005963;
  font-weight: bold;
}

.div-directory {
  cursor: pointer;
  background: white;
  font-size: 1.2rem;
  color: #808080;
  margin-top: 1rem;
  padding: 0.8rem;
  width: 100%;
  word-break: break-all;
  border-radius: 10px;
  max-height: 20vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 5px 5px 1px -3px #cacaca;
}

@media (max-height: 700px) {
  .div-directory {
    font-size: 0.8rem;
  }
}

.div-directory:hover {
  background-color: #09b086;
  color: white;
}

.div-directory:hover>div>button {
  color: white;
}

.div-directory-selected {
  background: #005963;
  color: white;
  font-weight: bold;
  box-shadow: 5px 5px 1px -1px #4d836b;
}

.div-directory-selected:hover {
  background: #005963;
  color: white;
  font-weight: bold;
}

.div-directory-selected>div>button {
  color: white;
}

.div-directory-name {
  width: 70%;
}

.directory-plus-btn {
  background: none;
  color: white;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 2rem;
  transition: all 0.3s ease;
}

.directory-plus-btn:hover {
  color: #09b086;
}

.div-directory-plus-btn-wrapper {
  text-align: center;
}

.directory-edit-btn {
  background: none;
  color: #cacaca;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.directory-edit-btn:hover {
  color: orangered !important;
  /* color: #005963; */
}

.directory-cancel-btn {
  background: none;
  color: orangered !important;
  border: none;
  cursor: pointer;
  font-size: 1.3rem;
  transition: all 0.3s ease;
  margin-left: 5px;
}

.directory-cancel-btn:hover {
  color: #005963;
}

.directory-delete-btn {
  margin-left: 10px;
}

.div-directory-dragEnter {
  background-color: #09b086;
  color: white;
}

/* 
클래스의 모든 자식: .클래스명 *
 */
.div-directory-dragEnter * {
  /* 마우스 올렸을 때, 이벤트 X */
  pointer-events: none;
}

.input-directory-name {
  font-size: 1.3rem;
  border: solid #cacaca 1px;
  border-radius: 3px;
  width: 90%;
}

.directory-check-btn {
  background: none;
  color: #7aff00 !important;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}

.div-directory-edit-btn-wrapper {
  display: flex;
}

.div-directory-check-btn {
  display: flex;
}