* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-wrapper {
  min-width: 1400px;
}

.Main {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 82vh;
}

/* @media (max-width: 940px), (max-height: 400px) {
  .Main {
    display: block;
    min-height: 82vh;
  }
} */
body {
  background-color: #aecdc4;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  min-height: 800px;
  min-width: 1300px;
  /* max-width: 225vh; */
}

header, form {
  min-height: 15vh;
  display: flex;
  align-items: center;
  margin: 0 1vh;
}

header {
  font-size: 2rem;
  justify-content: center;
}

form {
  justify-content: left;
}

form input, form button {
  padding: 1rem;
  font-size: 1rem;
  border: none;
  background: white;
  border-radius: 3px;
}

form button {
  color: #00d6c0;
  background: #f7fffe;
  cursor: pointer;
  transition: all 0.3s ease;
}

form button:hover {
  background: #00d6c0;
  color: white;
}

.url-container {
  display: flex;
  justify-content: left;
  align-items: center;
}

.url-list {
  width: 900px;
  list-style: none;
  margin-bottom: 10vh;
  word-break: break-all;
}

@media (max-width: 940px), (max-height: 400px) {
  .url-list {
    width: 100%;
    max-width: 900px;
    min-width: 470px;
    min-height: 82vh;
  }
}

.url-upper {
  margin: 0 0 5px 5px;
}

.url-preview-and-button {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dadada;
  padding: 10px 0 0 0;
}

.p-div {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* @media (max-width: 820px), (max-height: 400px) {
  .p-div {
    max-width: 30vh;
  }
} */

.url {
  margin: 1vh;
  background: #f2f2ea;
  font-size: 1.5rem;
  color: grey;
  border-radius: 10px;
  padding: 10px;
  display: block;
  overflow: auto;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.filter-url {
  padding: 1rem;
}

.trash-btn, .complete-btn {
  background: #005963;
  color: white;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
}

.complete-btn {
  background: #09b086;
}

.url_a {
  flex: 1;
  text-decoration: none;
  font-size: 0.9em;
  color: #005963;
}

.fa-trash, .fa-check {
  pointer-events: none;
}

.fall {
  transform: translateY(10rem) rotateZ(20deg);
  opacity: 0;
}

.completed {
  text-decoration: line-through;
  opacity: 0.5;
}

/*CUSTOM SELECTOR */

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background-image: none;
}

/* Custom Select */

.select {
  margin: 1rem 8px 1rem 1rem;
  position: relative;
  overflow: hidden;
}

select {
  color: #00d6c0;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  width: 199px;
}

/* Arrow */

.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  background: #00d6c0;
  cursor: pointer;
  pointer-events: none;
}

.preview-wrapper {
  display: flex;
  cursor: pointer;
}

.preview-wrapper p {
  margin: 5px 0 0 10px;
}

.url-url-p {
  font-size: 0.7em;
}

.url-description-p {
  font-size: 0.6em;
  color: #71a2a8;
  margin-top: 13px !important;
}

.url-regist-p {
  font-size: 0.6em;
  color: #aaaaaa;
}

.img-div {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  display: flex;
  background: #ffffff;
  border: #dadada solid 1px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

.img-div img {
  padding: 5px;
  min-width: 60px;
  min-height: 60px;
  max-width: 90px;
  max-height: 90px;
  border-radius: 5px;
}

.main-header {
  display: contents;
  cursor: pointer;
}

img.main-logo-img {
  width: 2.5em;
  height: 2em;
  margin: 0 0 0.5em 0.5em;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-button {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

/* 스크롤 스타일 적용, 메인(.vsc-initialized) 이하 모든 스크롤에 적용 */
.vsc-initialized ::-webkit-scrollbar {
  width: 8px;
}

.vsc-initialized ::-webkit-scrollbar-thumb {
  background: #5d957c;
  border-radius: 5px;
}

.vsc-initialized ::-webkit-scrollbar-track {
  background: #82b9a1;
  border-radius: 5px;
}